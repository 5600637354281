<template>
  <div class="container">
    <div class="row head feedback">
      <div class="col m10 s12">
        <span class="black-text text-darken-3"><strong>Teacher FeedBack</strong></span>
        <a @click="Goback()" style="color: #642c90"
          ><span
            ><strong> / {{ this.Name }}</strong></span
          ></a
        >
      </div>
      <div class="col m2 s12">
        <a class="waves-effect waves-light btn backbutton" @click="Goback()"
          ><span class="Tiny material-icons" style="position: relative; top: 7px; margin-right: 2px"
            >arrow_back</span
          ><strong>Go Back</strong></a
        >
      </div>
    </div>
    <div class="dic divider"></div>
    <div class="card-panel card">
      <div class="row">
        <div class="col l3 m6 s12 dtitle">
          <strong>Subject</strong>
          <select
            class="browser-default dropd"
            v-model="SelectedSubjectValue"
            @change="selectedsubjectid($event)"
          >
            <option value="subject" disabled selected>Select Here</option>
            <option
              v-for="subject in SubjectList"
              :key="subject.subjectId"
              :value="subject.subjectId"
            >
              {{ subject.subjectName }}
            </option>
            <!-- <option>{{this.SubjectList}}</option> -->
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <table class="qtbl highlight">
        <thead>
          <tr>
            <th>Question</th>
            <th>Subject</th>
            <th>Topic</th>
            <th>Chapter</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(course, index) in teacherFeedBack" :key="index">
            <td>
              <a
                @click="
                  StudentAnswer(
                    course.QuestionDiagramURL,
                    course.FullSolutionURL,
                    course.StudentAnswerUrl,
                    course.SubjectiveQuestionEvaluationId,
                    course.QuestionId,
                  )
                "
                >VIEW</a
              >
            </td>
            <td class="black-text">{{ course.SubjectName }}</td>
            <td class="black-text">{{ course.TopicName }}</td>
            <td class="black-text">{{ course.ChapterName }}</td>
            <td>
              <a
                @click="
                  StudentAnswers(
                    course.QuestionDiagramURL,
                    course.FullSolutionURL,
                    course.StudentAnswerUrl,
                    course.SubjectiveQuestionEvaluationId,
                    course.QuestionId,
                  )
                "
                >VIEW ANSWER & FEEDBACK</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal -->
    <Modal
      :show="showCampaignModal"
      @close="showCampaignModal = false"
      :showHeader="true"
      :showCloseBtn="true"
      width="1150px"
    >
      <template v-slot:body>
        <ul class="tabs">
          <li class="tab col s3">
            <a :class="{ active: selectedTab === 1 }" @click="selectedTab = 1">Question</a>
          </li>
          <li class="tab col s3">
            <a :class="{ active: selectedTab === 2 }" @click="selectedTab = 2">System Solution</a>
          </li>
          <li class="tab col s3">
            <a :class="{ active: selectedTab === 3 }" @click="selectedTab = 3">Student Solution</a>
          </li>
          <li class="tab col s3">
            <a :class="{ active: selectedTab === 4 }" @click="selectedTab = 4">Feedback</a>
          </li>
        </ul>
        <div class="row">
          <div class="col s12">
            <img
              v-if="selectedTab === 1"
              class="responsive-img"
              :src="`${this.questiondiagramURL}`"
              style="max-height: 400px"
            />
            <img
              v-if="selectedTab === 2"
              class="responsive-img"
              :src="`${this.fullsolutionURL}`"
              style="max-height: 400px"
            />
            <img
              v-if="selectedTab === 3"
              class="responsive-img"
              :src="`${this.studentanswerURL}`"
              style="max-height: 400px"
            />
            <div class="container" v-if="selectedTab === 4">
              <div>
                <h5>Marks</h5>
              </div>
              <div class="marks col s12">
                <input type="radio" name="emarks" id="zero" :value="0" v-model="maximummarks" />
                <input type="radio" name="emarks" id="one" :value="1" v-model="maximummarks" />
                <input type="radio" name="emarks" id="two" :value="2" v-model="maximummarks" />
                <input type="radio" name="emarks" id="three" :value="3" v-model="maximummarks" />
                <input type="radio" name="emarks" id="four" :value="4" v-model="maximummarks" />
                <input type="radio" name="emarks" id="five" :value="5" v-model="maximummarks" />
                <input type="radio" name="emarks" id="six" :value="6" v-model="maximummarks" />
                <input type="radio" name="emarks" id="seven" :value="7" v-model="maximummarks" />
                <input type="radio" name="emarks" id="eight" :value="8" v-model="maximummarks" />
                <input type="radio" name="emarks" id="nine" :value="9" v-model="maximummarks" />
                <input type="radio" name="emarks" id="ten" :value="10" v-model="maximummarks" />

                <label class="content" for="zero">0</label>
                <label class="content" for="one">1</label>
                <label class="content" for="two">2</label>
                <label class="content" for="three">3</label>
                <label class="content" for="four">4</label>
                <label class="content" for="five">5</label>
                <label class="content" for="six">6</label>
                <label class="content" for="seven">7</label>
                <label class="content" for="eight">8</label>
                <label class="content" for="nine">9</label>
                <label class="content" for="ten">10</label>
              </div>
              <br /><br /><br />
              <div>
                <h5>Additional Remarks</h5>
              </div>
              <!-- <div class="row">
     <div class="col s6">
       <p><label><input type="checkbox" class="filled-in" name="evalpoints" :value="1" v-model="addremarks" /><span style="color: black; font-weight: 500">Wrong Answer</span></label></p>
       <p><label><input type="checkbox" class="filled-in" name="evalpoints" :value="2" v-model="addremarks"/><span style="color: black; font-weight: 500">Long Answer</span></label></p>
       <p><label><input type="checkbox" class="filled-in" name="evalpoints" :value="3" v-model="addremarks"/><span style="color: black; font-weight: 500">Short Answer</span></label></p>
       <p><label><input type="checkbox" class="filled-in" name="evalpoints" :value="4" v-model="addremarks"/><span style="color: black; font-weight: 500">Units Missing</span></label></p>
       <p><label><input type="checkbox" class="filled-in" name="evalpoints" :value="5" v-model="addremarks"/><span style="color: black; font-weight: 500">Diagram Missing</span></label></p>
       <p><label><input type="checkbox" class="filled-in" name="evalpoints" :value="6" v-model="addremarks"/><span style="color: black; font-weight: 500">Labelling Wrong</span></label></p>
     </div>
    <div class="col s6">
       <p><label><input type="checkbox" class="filled-in" name="evalpoints" :value="7" v-model="addremarks"/><span style="color: black; font-weight: 500">Not Readable</span></label></p>
       <p><label><input type="checkbox" class="filled-in" name="evalpoints" :value="8" v-model="addremarks"/><span style="color: black; font-weight: 500">Keywords Missing</span></label></p>
       <p><label><input type="checkbox" class="filled-in" name="evalpoints" :value="9" v-model="addremarks"/><span style="color: black; font-weight: 500">Improper Definition</span></label></p>
       <p><label><input type="checkbox" class="filled-in" name="evalpoints" :value="10" v-model="addremarks"/><span style="color: black; font-weight: 500">Example Missing</span></label></p>
       <p><label><input type="checkbox" class="filled-in" name="evalpoints" :value="11" v-model="addremarks"/><span style="color: black; font-weight: 500">Wrong Reason</span></label></p>
       <p><label><input type="checkbox" class="filled-in" name="evalpoints" :value="12" v-model="addremarks"/><span style="color: black; font-weight: 500">Used Shortcut</span></label></p>
     </div>
  </div> -->
              <!-- <div class="row points" v-for="points in getEvaluationPoints" :key="points.Id">
           <p><label><input type="checkbox" class="filled-in" name="evalpoints" :value="points.Id" v-model="addremarks" />
             <span style="color:black; font-weight:500">{{points.EvaluationPoint}}</span>
           </label></p>
  </div> -->
              <div v-for="(points, index) in getEvaluationPoints" :key="points.Id">
                <div v-if="index == 0 || index % 1 == 0" class="columns">
                  <div class="column">
                    <p>
                      <label
                        ><input
                          type="checkbox"
                          class="filled-in"
                          name="evalpoints"
                          :value="points.Id"
                          v-model="addremarks"
                        />
                        <span style="color: black; font-weight: 500">{{
                          points.EvaluationPoint
                        }}</span>
                      </label>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="selectedTab === 2" @click="selectedTab = 4">
          <button
            class="waves-effect waves-light btn buttonborder"
            :class="[isMouseOnResumeButton ? ['orange', 'white-text'] : ['white', 'orange-text']]"
            v-on:mouseover="isMouseOnResumeButton = true"
            v-on:mouseleave="isMouseOnResumeButton = false"
          >
            PROVIDE FEEDBACK
          </button>
        </div>
        <div class="row" v-if="selectedTab === 3" @click="selectedTab = 4">
          <button
            class="waves-effect waves-light btn buttonborder"
            :class="[isMouseOnResumeButton ? ['orange', 'white-text'] : ['white', 'orange-text']]"
            v-on:mouseover="isMouseOnResumeButton = true"
            v-on:mouseleave="isMouseOnResumeButton = false"
          >
            PROVIDE FEEDBACK
          </button>
        </div>
        <div class="row" v-if="selectedTab === 4">
          <a class="waves-effect waves-light btn submitbutton" @click="feedback()"
            >SUBMIT FEEDBACK</a
          >
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import MobileAPI from "../Mobileapi";
import Api from "../Api";
import Modal from "../components/Modal.vue";

export default {
  data() {
    return {
      teacherFeedBackresponse: [],
      maximummarks: null,
      addremarks: [],
      Courses: [],
      listOfSubjectsForSelectedCourse: [],
      SelectedCourseCourseid: null,
      isMouseOnResumeButton: "",
      teacherFeedBack: [],
      subjectiveQuestionEvaluation: [],
      showCampaignModal: false,
      studentanswerURL: [],
      fullsolutionURL: " ",
      questiondiagramURL: " ",
      selectedTab: 1,
      SubjectiveQuestionEvaluationId: 0,
      evaluationId: 0,
      questionId: 0,
      UserId: 0,
      Name: "",
      courses: 1,
      SubjectList: [],
      SelectedSubjectValue: null,
    };
  },
  components: {
    Modal,
  },
  created() {
    const data = {
      QuestionId: this.questionId,
    };
    MobileAPI.getEvaluationPoints(data, (response) => {
      this.getEvaluationPoints = response.data[0];
      // console.log(this.getEvaluationPoints);
    });
  },
  activated() {
    this.UserId = this.$route.params.userId.UserId;
    this.SubjectId = this.$route.params.userId.SubjectId;
    this.Name = this.$route.params.userId.Name;
    this.SubjectList = this.$route.params.Allsubjects;
    this.SelectedSubjectValue = this.SubjectId;

    Api.getDataOfAffiliationCourses({}, (resp) => {
      this.$store.dispatch("showLoader", false);
      this.Courses = resp.data;
    });
    const data = {
      // StudentId: 40899,
      StudentId: this.$route.params.userId.UserId,
    };
    MobileAPI.teacherFeedBack(data, (response) => {
      this.teacherFeedBackresponse = response.data[0];
      this.teacherFeedBack = this.teacherFeedBackresponse.filter(
        (obj) => obj.SubjectId === this.SelectedSubjectValue,
      );
      // console.log(response.data);
    });
  },
  methods: {
    selectedsubjectid(event) {
      this.SelectedSubjectValue = Number(event.target.value);
      if (this.SelectedSubjectValue !== this.SubjectId) {
        this.SelectedSubjectValue = Number(event.target.value);
        const data = {
          // StudentId: 40899,
          StudentId: this.$route.params.userId.UserId,
        };
        this.$store.dispatch("showLoader", true);
        MobileAPI.teacherFeedBack(data, (response) => {
          this.$store.dispatch("showLoader", false);
          this.teacherFeedBackresponse = response.data[0];
          this.teacherFeedBack = this.teacherFeedBackresponse.filter(
            (obj) => obj.SubjectId === this.SelectedSubjectValue,
          );
        });
      } else {
        this.SelectedSubjectValue = this.SubjectId;
        const data = {
          // StudentId: 40899,
          StudentId: this.$route.params.userId.UserId,
        };
        this.$store.dispatch("showLoader", true);
        MobileAPI.teacherFeedBack(data, (response) => {
          this.$store.dispatch("showLoader", false);
          this.teacherFeedBackresponse = response.data[0];
          this.teacherFeedBack = this.teacherFeedBackresponse.filter(
            (obj) => obj.SubjectId === this.SelectedSubjectValue,
          );
        });
      }
    },
    modalReset() {
      this.maximummarks = "";
      this.addremarks = [];
    },
    bindFeedBackDetails() {
      const data = {
        // StudentId: 40899,
        StudentId: this.$route.params.userId.UserId,
      };
      MobileAPI.teacherFeedBack(data, (response) => {
        this.teacherFeedBackresponse = response.data[0];
        this.teacherFeedBack = this.teacherFeedBackresponse.filter(
          (obj) => obj.SubjectId === this.SelectedSubjectValue,
        );
      });
    },
    onCourseChange(event) {
      this.showCourseValidation = false;
      this.SelectedCourseCourseid = Number(event.target.value);
      this.listOfBatchesForSelectedCourse = this.listOfBatches.filter(
        (batch) => batch.CourseId === Number(event.target.value),
      );
    },
    Goback() {
      this.$router.push({ name: "EvaluateSubjectiveQuestions" });
      this.teacherFeedBack = [];
    },
    StudentAnswer(
      QuestionDiagramURL,
      FullSolutionURL,
      StudentAnswerUrl,
      SubjectiveQuestionEvaluationId,
      QuestionId,
    ) {
      this.selectedTab = 1;
      this.showCampaignModal = true;
      this.questiondiagramURL = QuestionDiagramURL;
      this.fullsolutionURL = FullSolutionURL;
      this.studentanswerURL = StudentAnswerUrl[0];
      this.evaluationId = SubjectiveQuestionEvaluationId;
      this.questionId = QuestionId;
    },
    StudentAnswers(
      QuestionDiagramURL,
      FullSolutionURL,
      StudentAnswerUrl,
      SubjectiveQuestionEvaluationId,
      QuestionId,
    ) {
      this.selectedTab = 3;
      this.showCampaignModal = true;
      this.questiondiagramURL = QuestionDiagramURL;
      this.fullsolutionURL = FullSolutionURL;
      this.studentanswerURL = StudentAnswerUrl[0];
      this.evaluationId = SubjectiveQuestionEvaluationId;
      this.questionId = QuestionId;
      this.modalReset();
    },
    feedback() {
      const user = this.$store.getters.user;
      const data = {
        TeacherId: Number(user.UserId),
        TeacherMarks: this.maximummarks,
        EvaluationPoints: this.addremarks,
        IsEvaluated: 1,
        SubjectiveQuestionEvaluationId: this.evaluationId,
        // TeacherId: 36456,
        // TeacherMarks: 5,
        // IsEvaluated: 1,
        // EvaluationPoints: 2,
        // SubjectiveQuestionEvaluationId: 1527,
      };
      // console.log(data);
      MobileAPI.subjectiveQuestionEvaluation(data, (response) => {
        // this.subjectiveQuestionEvaluation = response.data;
        if (response.responseCode === 500) {
          window.M.toast({
            html: "Something went wrong. Please try again later.",
            classes: "rounded error",
          });
        } else if (response.responseCode === 200) {
          window.M.toast({
            html: "Feedback Submitted Successfully",
            classes: "rounded success",
          });
          this.showCampaignModal = false;
          this.bindFeedBackDetails();
        }
      });
    },
  },
};
</script>

<style scoped>
.head {
  margin-top: 19px;
  left: 132px;
  font-size: 22px;
}
.feedback {
  margin-top: 19px;
  left: 209px;
  font-size: 22px;
  color: #642c90;
}
.dropd {
  border-radius: 5px;
  height: 36px;
  margin-top: 8px;
}
.dic {
  margin-top: 18.5px;
  border-radius: 1px solid;
  margin-bottom: 24.5px;
}
.card {
  border-radius: 5px;
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}
.dtitle {
  font-size: 18px;
  color: #333333;
}
table.qtbl {
  border-collapse: separate;
  border-spacing: 0 10px;
  font-size: 0.9rem;
}
table.qtbl thead tr {
  background-color: #6a2f85;
  color: #fff;
}
table.qtbl thead tr th {
  border-radius: 0;
  line-height: 28px;
}
table.qtbl thead tr th:first-child,
table.qtbl tbody tr td:first-child {
  border-radius: 5px 0 0 5px;
}
table.qtbl tbody tr {
  cursor: pointer;
  background-color: #fff;
  font-size: 14px;
  position: relative;
  border: none;
}
table.qtbl thead tr th:last-child,
table.qtbl tbody tr td:last-child {
  border-radius: 0 5px 5px 0;
}
table.qtbl tbody tr td {
  color: #6a2f85;
}
.submitbutton {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  width: 190px;
  height: 40px;
  font-size: 15px;
  margin-left: 870px;
}
.buttonborder {
  width: 190px;
  border: 1px solid #fbad26;
  height: 41px;
  font-size: 15px;
  margin-left: 870px;
  border-radius: 5px;
}
.marks input {
  display: none;
}
.content {
  display: inline-block;
  width: 42px;
  height: 35px;
  text-align: center;
  border: 1px solid #ddd;
  font-size: 18px;
  cursor: pointer;
  margin-right: 19px;
  margin-top: 8px;
  margin-bottom: 5px;
  border-radius: 3px;
  border-color: rgb(17, 8, 8);
  color: #333333;
}

#zero:checked ~ label[for="zero"],
#one:checked ~ label[for="one"],
#two:checked ~ label[for="two"],
#three:checked ~ label[for="three"],
#four:checked ~ label[for="four"],
#five:checked ~ label[for="five"],
#six:checked ~ label[for="six"],
#seven:checked ~ label[for="seven"],
#eight:checked ~ label[for="eight"],
#nine:checked ~ label[for="nine"],
#ten:checked ~ label[for="ten"] {
  background: #338510;
  color: #fff;
}
.remarks {
  background-color: #1b6aff;
}
.message {
  background-color: white;
  color: green;
}
.backbutton {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  font-size: 14px;
  width: 130px;
}
.points {
  max-height: 100px;
  /* display: flex; */
}
</style>
